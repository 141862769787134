import styled from 'styled-components'
import { TextWrapper as BaseTextWrapper } from '../base'
import variables from '../variables'
import { Heading } from '../../components'

export const Container = styled.div`
  width: 100%;
  background: ${variables.colors.silverTree.darker};
  color: white;
`
export const TextWrapper = styled(BaseTextWrapper)`
  padding-top: 100px !important;
  padding-bottom: 150px !important;
  position: relative;

  @media ${variables.media.sm} {
    padding-bottom: 160px !important;
  }
`
export const SquareImage1 = styled.img`
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 250px;
  transform: translateY(60%) rotate(45deg);
  bottom: 0;
  left: 30px;
`
export const SquareImage2 = styled(SquareImage1)`
  left: auto;
  z-index: 1;
  left: 345px;
  width: 50px;
  height: 50px;
  bottom: 0;
  transform: translateY(70%) rotate(-135deg);
`
export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 0;
`
export const NotFoundHeader = styled(Heading)`
  font-size: 100px;
  margin: 0;
  animation: slideUp 0.5s ease-in-out;
`
export const NotFoundText = styled.p`
  font-size: 20px;
  animation: slideUp 0.5s ease-in-out 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
`
export const NotFoundButton = styled.div`
  margin: 20px 0;
  animation: slideUp 0.5s ease-in-out 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
`

export const NotFoundBlogContainer = styled.div`
  color: white;

  p {
    color: white;
  }

  h1 {
    color: white;
  }

  div {
    padding: 0;
  }
`
