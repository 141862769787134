import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/404'
import system10 from '../../assets/system10.svg'
import { LatestBlogList } from '../components'
import { filterPublishedEdges } from '../utils/gatsby'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function NotFoundPage(props) {
  const { data } = props
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)

  const blogEdges = data.allMdx.edges
  const publishedEdges = filterPublishedEdges(blogEdges)
  const frontmatters = _.map(publishedEdges, e => e.node)
  console.log(frontmatters)
  const blogs = _.sortBy(frontmatters, blog => blog.frontmatter.date).reverse()
  const latestBlog = _.slice(blogs, 0, 3)
  return (
    <PageLayout
      sharingTitle={node.title}
      description={node.metaDescription}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <styles.Container>
        <styles.TextWrapper>
          <styles.NotFoundContainer>
            <styles.NotFoundHeader>{node.header}</styles.NotFoundHeader>
            <styles.NotFoundText>
              {node.text}
            </styles.NotFoundText>
            <styles.NotFoundBlogContainer>
              <LatestBlogList header={data.latestBlogHeader} blog={latestBlog} />
            </styles.NotFoundBlogContainer>
          </styles.NotFoundContainer>
          <styles.SquareImage1 src={system10} />
          <styles.SquareImage2 src={system10} />
        </styles.TextWrapper>
      </styles.Container>
    </PageLayout>
  )
}

NotFoundPage.propTypes = propTypes

export const contentQuery = graphql`
  query Get404Content {
    allYaml(filter: {file: {eq: "404"}}) {
      edges {
        node {
          header
          text
          buttonText
          metaDescription
        }
      }
    },
    allMdx(filter: {frontmatter: { path: { regex: "^/blog/" } }}) {
      edges {
        node {
          frontmatter {
            path
            title
            introduction
            tags
            coverImgLink
            date
            publish
          }
          coverImg {
            childImageSharp {
              fluid(maxWidth: 330, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
